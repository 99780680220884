import './utils/i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { Buffer } from 'buffer';
import { store, persistor } from '@store';
import initFirebaseApp from '@utils/firebase';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleOauthClientId } from '@constants/configs';
import { CookiesProvider } from 'react-cookie';
import * as serviceWorker from './serviceWorker';
import Router from './navigation';

import 'simplebar/dist/simplebar.min.css';
import './index.css';

initFirebaseApp();
(window as any).Buffer = Buffer;

const tagManagerArgs = {
  gtmId: 'GTM-NSF59XG',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={googleOauthClientId}>
        <PersistGate loading={null} persistor={persistor}>
          <CookiesProvider>
            <Router />
          </CookiesProvider>
        </PersistGate>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
