import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@services/baseQuery';
import {
  IChangeAssetsResponse,
  OrderCondition,
  OrderConditionStatus,
  OrderStatus,
  OrderType,
  TCancelOrderArgs,
  TChangeAssetsArgs,
  TChangeLowAssetsArgs,
  TGetUserOrderParams,
  TGetUserOrdersResponse,
  TPlaceOrderArgs,
  TPlaceOrderResponse,
} from './types';

export const ORDER_API_REDUCER_KEY = 'ORDER_API_REDUCER_KEY';

const GET_USER_ORDERS_TAG = 'user-orders';
const GET_USER_LIMIT_OPEN_ORDERS_TAG = 'user-limit-open-orders';
const GET_USER_STOP_LIMIT_OPEN_ORDERS = 'user-stop-limit-open-orders';

const orderApi = createApi({
  reducerPath: ORDER_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [GET_USER_ORDERS_TAG, GET_USER_LIMIT_OPEN_ORDERS_TAG, GET_USER_STOP_LIMIT_OPEN_ORDERS],
  endpoints: (builder) => ({
    getUserOrders: builder.query<TGetUserOrdersResponse, TGetUserOrderParams>({
      query: (values) => ({
        path: '/v1/orders',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: (_, __, args) => {
        if (
          args.condition === OrderCondition.STOP_LIMIT &&
          args.conditionStatus === OrderConditionStatus.PENDING
        ) {
          return [GET_USER_STOP_LIMIT_OPEN_ORDERS];
        }
        if (args.status === OrderStatus.PENDING) {
          return [GET_USER_LIMIT_OPEN_ORDERS_TAG];
        }

        return [GET_USER_ORDERS_TAG];
      },
    }),
    placeOrder: builder.mutation<TPlaceOrderResponse, TPlaceOrderArgs>({
      query: (values) => ({
        path: '/v1/orders',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: (_, __, args) => {
        const isStopLimit = args.conditions && !!args.conditions.find((con) => con.price);
        if (isStopLimit) {
          return [GET_USER_STOP_LIMIT_OPEN_ORDERS];
        }
        switch (args.type) {
          case OrderType.LIMIT:
            return [GET_USER_LIMIT_OPEN_ORDERS_TAG];
          default:
            return [GET_USER_ORDERS_TAG];
        }
      },
    }),
    cancelStopLimitOrder: builder.mutation<void, TCancelOrderArgs>({
      query: (values) => ({
        path: '/v1/orders',
        method: 'DELETE',
        data: values,
      }),
      invalidatesTags: [GET_USER_STOP_LIMIT_OPEN_ORDERS],
    }),
    cancelLimitOrder: builder.mutation<void, TCancelOrderArgs>({
      query: (values) => ({
        path: '/v1/orders',
        method: 'DELETE',
        data: values,
      }),
      invalidatesTags: [GET_USER_LIMIT_OPEN_ORDERS_TAG],
    }),
    bulkCancelOrders: builder.mutation<void, TCancelOrderArgs>({
      query: (values) => ({
        path: '/v1/orders',
        method: 'DELETE',
        data: values,
      }),
      invalidatesTags: [GET_USER_LIMIT_OPEN_ORDERS_TAG, GET_USER_STOP_LIMIT_OPEN_ORDERS],
    }),
    changeLowAssets: builder.mutation<IChangeAssetsResponse[], TChangeLowAssetsArgs>({
      query: (values) => ({
        path: '/v1/change-low-assets',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_USER_ORDERS_TAG],
    }),
    changeAssets: builder.mutation<IChangeAssetsResponse[], TChangeAssetsArgs>({
      query: (values) => ({
        path: '/v1/change-assets',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_USER_ORDERS_TAG],
    }),
  }),
});

export default orderApi;
