export const EN_US = 'en-US';
export const FA_IR = 'fa-IR';

export const localeOptions = {
  [EN_US]: {
    id: EN_US,
    name: 'English',
    direction: 'ltr',
  },
  [FA_IR]: {
    id: FA_IR,
    name: 'فارسی',
    direction: 'rtl',
  },
};

export default EN_US;
