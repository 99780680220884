import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@services/baseQuery';
import { TGetUserTradesResponse, TGetUserTradesParams } from './types';

export const TRADE_API_REDUCER_KEY = 'TRADE_API_REDUCER_KEY';

const tradeApi = createApi({
  reducerPath: TRADE_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getUserTrades: builder.query<TGetUserTradesResponse, TGetUserTradesParams>({
      query: (values) => ({
        path: '/v1/trades',
        method: 'GET',
        options: {
          params: values,
        },
      }),
    }),
  }),
});

export default tradeApi;
