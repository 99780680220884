import { createApi } from '@reduxjs/toolkit/query/react';
import * as R from 'ramda';
import baseQuery from '@services/baseQuery';
import {
  TConfirmWithdrawalRequestArgs,
  TGetFinancialExchangeParams,
  TGetFinancialExchangeResponse,
  TSendWithdrawalRequestArgs,
  TGetTransactionsParams,
  TGetTransactionsResponse,
  TGetWithdrawInfoResponse,
  IWithdrawInfo,
} from './types';

export const TRANSACTIONS_API_REDUCER_KEY = 'TRANSACTIONS_API_REDUCER_KEY';

const GET_TRANSACTIONS_TAG: string = 'transactions';
const GET_FINANCIAL_EXCHANGE_TAG: string = 'financial-exchange';
const GET_WITHDRAW_INFO_TAG: string = 'withdraw-info';

const transactionApi = createApi({
  reducerPath: TRANSACTIONS_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [GET_TRANSACTIONS_TAG, GET_FINANCIAL_EXCHANGE_TAG, GET_WITHDRAW_INFO_TAG],
  endpoints: (builder) => ({
    getTransactions: builder.query<TGetTransactionsResponse, TGetTransactionsParams>({
      query: (values) => ({
        path: '/v1/transactions',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_TRANSACTIONS_TAG],
    }),
    sendWithdrawRequest: builder.mutation<void, TSendWithdrawalRequestArgs>({
      query: (values) => ({
        path: '/v1/transactions',
        method: 'POST',
        data: values,
      }),
    }),
    confirmWithdrawRequest: builder.mutation<void, TConfirmWithdrawalRequestArgs>({
      query: (values) => ({
        path: '/v1/transactionConfirmation',
        method: 'POST',
        data: values,
      }),
    }),
    getWithdrawInfo: builder.query<TGetWithdrawInfoResponse, void>({
      query: () => ({
        path: '/v1/withdraw-info',
        method: 'GET',
      }),
      transformResponse: (response: IWithdrawInfo[]) =>
        R.indexBy<IWithdrawInfo, string>(R.prop('token'), response),
      providesTags: [GET_WITHDRAW_INFO_TAG],
    }),
    getFinancialExchangeHistory: builder.query<
      TGetFinancialExchangeResponse,
      TGetFinancialExchangeParams
    >({
      query: (values) => ({
        path: '/v1/transactions/financial-exchange',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_FINANCIAL_EXCHANGE_TAG],
    }),
  }),
});

export default transactionApi;
