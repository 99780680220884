import pathnames from './pathnames';

export const application = {
  apiUrl: process.env.REACT_APP_API_URL || 'https://apitest.irandax.com',
  websocketUrl: process.env.REACT_APP_WS_URL || 'https://wstest.irandax.com',
};

export const trackingUrl = process.env.REACT_APP_TRACKING_URL || 'http://localhost:3000';

export const host = process.env.REACT_APP_FED_HOST || 'http://localhost:3000';
export const googleOauthClientId =
  process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ||
  '615446330225-kr64kdk54tmoh22eqn13d382dnpges5e.apps.googleusercontent.com';

export const resetPasswordEmailUrl =
  process.env.REACT_APP_RESET_PASSWORD_EMAIL_URL || `/${pathnames.RESET_PASSWORD.split('/')[0]}`;
export const rulesUrl = process.env.REACT_APP_RULES_URL || 'https://zarindax.com/privacy-policy';
export const orderbookLimit = process.env.REACT_APP_ORDERBOOK_LIMIT || 50;
export const tradesLimit = process.env.REACT_APP_TRADES_LIMIT || 30;
export const defaultPollingInterval = (process.env.DEFAULT_POLLING_INTERVAL ?? 15000) as number;

export const androidAppDownloadLink = process.env.REACT_APP_ANDROID_APP_DOWNLOAD_LINK || '#';

export const landingLink = process.env.REACT_APP_LANDING_LINK || 'https://zarindax.com';
export const domainName = process.env.REACT_APP_DOMAIN || 'zarindax.com';
