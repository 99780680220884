/* eslint-disable no-nested-ternary */
import { createApi } from '@reduxjs/toolkit/query/react';
import * as R from 'ramda';
import baseQuery from '@services/baseQuery';
import {
  TGetUserWalletsParams,
  TGetUserWalletsResponse,
  TGetUserWalletParams,
  TGetUserWalletResponse,
  IWallet,
} from './types';

export const WALLET_API_REDUCER_KEY = 'WALLET_API_REDUCER_KEY';

const USER_WALLET_TAG = 'user-wallet';

function providesList<R extends TGetUserWalletsResponse, T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...Object.keys(resultsWithIds).map((id) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}

const walletApi = createApi({
  reducerPath: WALLET_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [USER_WALLET_TAG],
  endpoints: (builder) => ({
    getUserWallets: builder.query<TGetUserWalletsResponse, TGetUserWalletsParams>({
      query: (values) => ({
        path: '/v1/wallets',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      transformResponse: (response: IWallet[]) =>
        R.indexBy<IWallet, string>(R.prop('token'), response),
      providesTags: (result) => (result ? providesList(result, USER_WALLET_TAG) : []),
    }),
    getUserWallet: builder.query<TGetUserWalletResponse, TGetUserWalletParams>({
      query: (token) => ({
        path: '/v1/wallets',
        method: 'GET',
        options: {
          params: {
            token,
          },
        },
      }),
      providesTags: (result, error, id) => [{ type: USER_WALLET_TAG, id }],
      transformResponse: (response: IWallet[]) =>
        R.indexBy<IWallet, string>(R.prop('token'), response),
    }),
  }),
});

export default walletApi;
