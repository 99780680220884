import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ToastConfig from '@components/@kit/ToastConfig';
import FA_IR from '@constants/locales';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import { selectLang } from '@modules/app/selectors/langSelector';
import { selectTheme } from '@modules/app/selectors/themeSelector';
import i18n from '@utils/i18n';

import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import pathnames from '@constants/pathnames';
import SuspenseLoading from '@components/@kit/Loadings/SuspenseLoading';
import { useAddTagMutation } from '@hooks/services/track/trackApiHooks';
import { domainName } from '@constants/configs';
import Cookies from 'universal-cookie';

const App = React.lazy(() => import('./app'));
const User = React.lazy(() => import('./user'));

const cookie = new Cookies();

const Router: React.FC = function () {
  const dispatch = useAppDispatch();
  const theme = useAppSelector(selectTheme);
  const lang = useAppSelector(selectLang);
  const [addTag] = useAddTagMutation();

  const setIdInLocalStorage = useCallback(() => {
    const res = new URLSearchParams(window.location.search);
    const id = res.get('id');
    if (id) {
      const d = new Date();
      d.setFullYear(d.getFullYear() + 1);

      cookie.set('UID', id, {
        domain: `.${domainName}`,
        path: '/',
        expires: d,
      });
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
    setIdInLocalStorage();
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else if (theme === 'light') {
      document.documentElement.classList.remove('dark');
    }
  }, [addTag, dispatch, lang, setIdInLocalStorage, theme]);

  return (
    <section className={`${lang === FA_IR ? 'font-fa' : 'font-en'}`}>
      <React.Suspense fallback={<SuspenseLoading />}>
        <BrowserRouter>
          <Routes>
            <Route path={`${pathnames.USER}/*`} element={<User />} />
            <Route path={`${pathnames.APP}/*`} element={<App />} />
            {/* <Route path={names.ERROR} exact component={ErrorScreen} /> */}
            {/* <Route path={names.NOT_FOUND} exact component={NotFoundScreen} /> */}
            {/* <Redirect to={names.NOT_FOUND}/> */}
          </Routes>
          <ToastConfig />
        </BrowserRouter>
      </React.Suspense>
    </section>
  );
};

export default Router;
