import type { Middleware } from '@reduxjs/toolkit';
import { ORDER_API_REDUCER_KEY } from '@services/v1/order/orderApi';
import trackingApi from '@services/tracking/trackingApi';
import { OrderSide, OrderType } from '@services/v1/order/types';
import { TagTypes } from '@services/tracking/types';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const rtkQueryTracking: Middleware = (store) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (action.type === `${ORDER_API_REDUCER_KEY}/executeMutation/fulfilled`) {
    const cookieId = cookies.get('UID');
    const { side, price, type, amount, filledPrice } = action.payload;
    store.dispatch(
      trackingApi.endpoints.addTag.initiate({
        id: cookieId,
        tag: side === OrderSide.BUY ? TagTypes.TRADE_BUY : TagTypes.TRADE_SELL,
        value:
          OrderType.LIMIT === type
            ? (Number(price) * Number(amount)).toString()
            : (Number(filledPrice) * Number(amount)).toString(),
      }) as any
    );
  }

  return next(action);
};

export default rtkQueryTracking;
