import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@services/baseQuery';
import {
  TLastTradesParams,
  TLastTradesResponse,
  TOrderbookParams,
  TOrderbookResponse,
} from './types';

export const MARKET_INFO_API_REDUCER_KEY = 'MARKET_INFO_API_REDUCER_KEY';

const GET_LAST_TRADES_TAG: string = 'last-trades';
const GET_ORDERBOOK_TAG: string = 'orderbook';

const marketInfoApi = createApi({
  reducerPath: MARKET_INFO_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [GET_LAST_TRADES_TAG, GET_ORDERBOOK_TAG],
  endpoints: (builder) => ({
    getLastTrades: builder.query<TLastTradesResponse, TLastTradesParams>({
      query: (values) => ({
        path: '/v1/last-trades',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_LAST_TRADES_TAG],
    }),
    getOrderbook: builder.query<TOrderbookResponse, TOrderbookParams>({
      query: (values) => ({
        path: '/v1/orderbook',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_ORDERBOOK_TAG],
    }),
  }),
});

export default marketInfoApi;
