import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import jMoment from 'moment-jalaali';
import defaultLang, { FA_IR, EN_US } from '../constants/locales';
import faResource from '../locales/fa-IR.json';
import enResource from '../locales/en-US.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  [EN_US]: {
    translation: enResource,
  },
  [FA_IR]: {
    translation: faResource,
  },
};

jMoment.loadPersian({
  dialect: 'persian-modern',
  usePersianDigits: false,
});

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    lng: defaultLang, // default language
    debug: false,
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
