import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@services/baseQuery';
import { TGetUserIncomeStatementResponse, TGetUserIncomeStatementParams } from './types';

export const INCOME_STATEMENT_API_REDUCER_KEY = 'INCOME_STATEMENT_API_REDUCER_KEY';

const GET_INCOME_STATEMENT_TAG: string = 'income-statement';

const incomeStatementApi = createApi({
  reducerPath: INCOME_STATEMENT_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  tagTypes: [GET_INCOME_STATEMENT_TAG],
  endpoints: (builder) => ({
    getUserIncomeStatement: builder.query<
      TGetUserIncomeStatementResponse,
      TGetUserIncomeStatementParams
    >({
      query: (values) => ({
        path: '/v1/transactions/income-statement',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_INCOME_STATEMENT_TAG],
    }),
  }),
});

export default incomeStatementApi;
