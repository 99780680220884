export const DARK = 'dark';
export const LIGHT = 'light';

export const themeOptions = {
  [DARK]: {
    id: DARK,
    name: 'Dark',
  },
  [LIGHT]: {
    id: LIGHT,
    name: 'Light',
  },
};

export default LIGHT;
