/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import authApi from '@services/v2/auth/authApi';

type AuthState = {
  isLoggedIn: boolean;
};

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('@accessToken'),
};

export const AUTH_REDUCER_KEY = 'AUTH_REDUCER_KEY';

export const authSlice = createSlice({
  name: AUTH_REDUCER_KEY,
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.authInit.matchFulfilled, (state, { payload }) => {
      state.isLoggedIn = !!payload.token;
    });
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.isLoggedIn = !!payload.token;
    });
  },
});

export const { actions: Creators } = authSlice;

export const { logout } = Creators;

export default authSlice.reducer;
