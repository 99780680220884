import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { omit } from 'ramda';
import axios from 'axios';
import axiosInvoke from './axiosInvoke';
import i18n from '../../utils/i18n';
import IBaseQueryOpts, { IBaseQueryError, TFieldErrorOpts, TResponseErrorDetail } from './types';

const baseQuery = (): BaseQueryFn<IBaseQueryOpts, unknown, unknown> => async (baseQueryOpts) => {
  const { method, path, data, options } = baseQueryOpts;

  const apiOptions = {
    displayError: true,
    ...((options && options.api) || {}),
  };

  const newOptions = omit(['api'], options);

  try {
    const result = await axiosInvoke({
      method,
      path,
      data,
      options: newOptions,
      authenticate: apiOptions.authenticate !== false,
    });
    return { data: result.data };
  } catch (err) {
    const e = err as IBaseQueryError;
    let message;
    if (e.response) {
      if (e.response.data) {
        if (e.response.data.details) {
          e.fieldError = e.response.data.details.reduce(
            (res: TFieldErrorOpts, item: TResponseErrorDetail) => {
              const { field, issue } = item;
              const obj = { ...res };
              if (!obj[field]) {
                obj[field] = issue;
              }
              return obj;
            },
            {}
          );
        }
        if (e.response.data.message) {
          message = e.response.data.message;
        } else if (e.response.status === 413) {
          message = i18n.t('File size is too large to upload');
        }
      }
    } else if (axios.isCancel(e) && !e.request) {
      message = i18n.t('Check your internet connection and try again');
    } else {
      message = process.env.NODE_ENV === 'development' ? e.message : null;
    }

    if (message) {
      e.message = message;
    }
    return { error: e };
  }
};

export default baseQuery();
