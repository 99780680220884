import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@services/baseQuery';
import {
  TPriceByDayParams,
  TPriceByDayResponse,
  TPriceByMinuteParams,
  TPriceByMinuteResponse,
  TPriceTickParams,
  TPriceTickerParams,
  TPriceTickerResponse,
  TPriceTickResponse,
} from './types';

export const PRICE_API_REDUCER_KEY = 'PRICE_API_REDUCER_KEY';

const GET_PRICE_BY_DAY_TAG: string = 'price/day-history';
const GET_PRICE_BY_MINUTE_TAG: string = 'price/minute-history';
const GET_PRICE_TICK_HISTORY_TAG: string = 'price/tick-history';
const GET_PRICE_TICKER_TAG: string = 'price/ticker';

const priceApi = createApi({
  reducerPath: PRICE_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [
    GET_PRICE_BY_DAY_TAG,
    GET_PRICE_BY_MINUTE_TAG,
    GET_PRICE_TICK_HISTORY_TAG,
    GET_PRICE_TICKER_TAG,
  ],
  endpoints: (builder) => ({
    getPriceByDay: builder.query<TPriceByDayResponse, TPriceByDayParams>({
      query: (values) => ({
        path: '/v1/price/day-history',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_PRICE_BY_DAY_TAG],
    }),
    getPriceByMinute: builder.query<TPriceByMinuteResponse, TPriceByMinuteParams>({
      query: (values) => ({
        path: '/v1/price/minute-history',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_PRICE_BY_MINUTE_TAG],
    }),
    getPriceTickHistory: builder.query<TPriceTickResponse, TPriceTickParams>({
      query: (values) => ({
        path: '/v1/price/tick-history',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_PRICE_TICK_HISTORY_TAG],
    }),
    getPriceTicker: builder.query<TPriceTickerResponse, TPriceTickerParams>({
      query: (values) => ({
        path: '/v1/price/ticker',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_PRICE_TICKER_TAG],
    }),
  }),
});

export default priceApi;
