import moment from 'moment';
import momentJalaali from 'moment-jalaali';
import * as R from 'ramda';
import { IOrderbookItem } from '@services/v1/marketInfo/types';
import { AuthInitError, TRecipientType } from '@services/v2/auth/types';
import pathnames from '@constants/pathnames';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { NavigateFunction } from 'react-router-dom';
import { defaultDecimal } from '@constants/defaultValues';
import { FA_IR } from '@constants/locales';
import { TagTypes } from '@services/tracking/types';
import i18n from './i18n';

export const calculateScrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute(
    'style',
    'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;'
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

export function debounce(fn: Function, ms = 300) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}

export function search<T extends object, U extends keyof T>(
  value: string,
  array: T[],
  params: U[]
): T[] | undefined {
  const searchedItems: any[] = [];
  let i;
  let j;

  if (array) {
    for (i = 0; i < params.length; i++) {
      for (j = 0; j < array.length; j++) {
        const item = array[j];
        const isStringIncluded = R.includes(R.toLower(value));
        if (isStringIncluded(R.toLower(String(item[params[i]])))) {
          searchedItems.push(array[j]);
        }
      }
    }

    return searchedItems;
  }
}

export function uniqueValues<T>(array: T[]) {
  return array.reduce((arr: T[], item) => {
    if (!arr.some((i: any) => JSON.stringify(i) === JSON.stringify(item))) arr.push(item);
    return arr;
  }, []);
}

export const stringToNumber = (content: string) => content.replace(/,/g, '');
export const scientificToDecimal = (value: string | number) => {
  let number = String(value);
  const numberHasSign = number.startsWith('-') || number.startsWith('+');
  const sign = numberHasSign ? number[0] : '';
  number = numberHasSign ? number.replace(sign, '') : number;
  // if the number is in scientific notation remove it
  // eslint-disable-next-line
  if (/\d+\.?\d*e[\+\-]*\d+/i.test(number)) {
    const zero = '0';
    const parts = number.toLowerCase().split('e'); // split into coeff and exponent
    const e = parts.pop(); // store the exponential part
    let l = Math.abs(+e!); // get the number of zeros
    const s = +e! / l;
    const coeffArray = parts[0].split('.');
    if (s === -1) {
      coeffArray[0] = Math.abs(Number(coeffArray[0])).toString();
      number = `${zero}.${new Array(l).join(zero)}${coeffArray.join('')}`;
    } else {
      const dec = coeffArray[1];
      if (dec) l -= dec.length;
      number = coeffArray.join('') + new Array(l + 1).join(zero);
    }
  }
  return `${sign}${number}`;
};

export const numberWithCommas = (value: string) => {
  const parts = value.toString().split('.');
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts.length > 1 ? `.${parts[1]}` : '');
};

export const numberToString = (value: string) => {
  const number = scientificToDecimal(value);
  return numberWithCommas(number);
};

export const fixedToWithoutRound = (value: string, precision = 0) => {
  if ((value || +value === 0) && !Number.isNaN(value)) {
    const newValue = String(value)[0] === '.' ? Number(value) : value;
    const number = scientificToDecimal(newValue);
    const regex = new RegExp(`^-?\\d+(?:.\\d{0,${precision}})?`);
    const match = number.match(regex);
    const result = match && match.length ? match[0] : number;
    return precision === 0 && result[result.length - 1] === '.'
      ? result.slice(0, result.length - 1)
      : result;
  }
  return undefined;
};

export const formatNumber = (number: string | number, fraction: number = defaultDecimal) => {
  if ((number || number === 0) && !Number.isNaN(Number(number)))
    return numberToString(fixedToWithoutRound(number.toString(), fraction) ?? '');
  return number;
};

type TCalculateMarketTotalPrice = (
  amount: number,
  orders: IOrderbookItem[]
) => {
  computedAmount?: number | undefined;
  totalPrice: number;
  fixedPrice: number;
};

export const i18nizeDate = (date: string) => {
  if (!date) return '';
  if (i18n.language === FA_IR) {
    return stringToNumber(momentJalaali(date).format('HH:mm jYYYY/jMM/jDD'));
  }
  return stringToNumber(moment(date).format('YYYY/MM/DD HH:mm'));
};

export const calculateMarketTotalPrice: TCalculateMarketTotalPrice = (amount, orders) => {
  let remainedAmount = Number(amount);
  const orderbook = orders;
  let totalPrice = 0;
  let i = 0;
  if (!orderbook.length) {
    return {
      computedAmount: 0,
      totalPrice: 0,
      fixedPrice: 0,
    };
  }
  while (remainedAmount) {
    if (!orderbook[i]) {
      break;
    }
    const orderAmount = Number(orderbook[i].amount);
    if (orderAmount <= remainedAmount) {
      totalPrice += orderAmount * Number(orderbook[i].price);
      remainedAmount -= orderAmount;
    } else {
      totalPrice += remainedAmount * Number(orderbook[i].price);
      remainedAmount = 0;
    }
    i++;
  }

  return {
    computedAmount: Number(amount) - remainedAmount,
    totalPrice: !remainedAmount ? totalPrice : 0,
    fixedPrice: !remainedAmount ? totalPrice / Number(amount) : 0,
  };
};

export const calculateMarketAmount = (totalPrice: number, orders: IOrderbookItem[]) => {
  let remainedTotalPrice = totalPrice;
  let amount = 0;
  let i = 0;
  if (!orders || !orders.length) {
    return 0;
  }
  while (remainedTotalPrice) {
    if (!orders[i]) {
      break;
    }
    const orderTotalPrice = Number(orders[i].amount) * Number(orders[i].price);
    if (orderTotalPrice <= remainedTotalPrice) {
      amount += Number(orders[i].amount);
      remainedTotalPrice -= orderTotalPrice;
    } else {
      amount += remainedTotalPrice / Number(orders[i].price);
      remainedTotalPrice = 0;
    }
    i++;
  }
  if (!remainedTotalPrice) return amount;
  return 0;
};

export function paginate(arr: any[], size: number) {
  return arr.reduce((acc, val, i) => {
    const idx = Math.floor(i / size);
    const page = acc[idx] || (acc[idx] = []);
    page.push(val);

    return acc;
  }, []);
}
export function chunkString(str: string, size: number) {
  const chunkArr = str.match(new RegExp(`.{1,${size}}`, 'g'));
  const reversedArr = i18n.dir(i18n.language) === 'rtl' ? chunkArr?.reverse() : chunkArr;
  return reversedArr;
}

/**
 * If you don't care about primitives and only objects then this function
 * is for you, otherwise look elsewhere.
 * This function will return `false` for any valid json primitive.
 * EG, 'true' -> false
 *     '123' -> false
 *     'null' -> false
 *     '"I'm a string"' -> false
 */
export function tryParseJSONObject(jsonString: string): object | boolean {
  try {
    const o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return false;
}

export function isPhone(value: string) {
  return /^09\d{9}$/gi.test(value);
}

export function authRoutingHub(
  navigateFn: NavigateFunction,
  errorCode: AuthInitError,
  submitTag: (tag: TagTypes) => Promise<void>,
  state: { recipient?: string; password?: string; otpToken?: string }
) {
  // If account is not confirmed yet, redirect it to confirm account form.
  if (
    errorCode === AuthInitError.NOT_CONFIRMED_EMAIL ||
    errorCode === AuthInitError.NOT_CONFIRMED_PHONE_NUMBER
  ) {
    submitTag(TagTypes.SEND_OTT_CODE_REGISTER);
    const type =
      errorCode === AuthInitError.NOT_CONFIRMED_EMAIL
        ? TRecipientType.EMAIL_VERIFICATION
        : TRecipientType.PHONE_NUMBER_VERIFICATION;
    navigateFn(`/${pathnames.USER}/${pathnames.AUTH}/${pathnames.VERIFY_ACCOUNT}`, {
      state: { ...state, type },
    });
  }

  // If SMS 2FA is enabled, user will be navigated to SMS 2FA form.
  if (errorCode === AuthInitError.SMS_AUTH_TOKEN_REQUIRED) {
    submitTag(TagTypes.SMS_2FA);
    const type = TRecipientType.SMS_2FA;
    navigateFn(`/${pathnames.USER}/${pathnames.SMS_AUTH}`, {
      state: { ...state, type },
    });
  }

  // If Google 2FA is enabled, user will be navigated to Google 2FA form.
  if (errorCode === AuthInitError.GOOGLE_AUTH_TOKEN_REQUIRED) {
    submitTag(TagTypes.GOOGLE_2FA);
    navigateFn(`/${pathnames.USER}/${pathnames.GOOGLE_AUTH}`, {
      state: { ...state },
    });
    toast(t('Your Google two-factor login is enabled. Check your Google Authenticator app.'), {
      type: 'info',
    });
  }

  if (errorCode === AuthInitError.EMAIL_NOT_REGISTERED) {
    submitTag(TagTypes.SEND_OTT_CODE_REGISTER);
    navigateFn(`/${pathnames.USER}/${pathnames.AUTH}/${pathnames.REGISTER}`, {
      state: { ...state },
    });
  }

  if (errorCode === AuthInitError.PHONE_NUMBER_NOT_REGISTERED) {
    submitTag(TagTypes.SEND_OTT_CODE_REGISTER);
    navigateFn(`/${pathnames.USER}/${pathnames.AUTH}/${pathnames.REGISTER}`, {
      state: { ...state },
    });
  }

  if (errorCode === AuthInitError.USER_STATIC_PASSWORD_IS_NULL) {
    toast(
      t(
        'You have not set password on your account. Please set the password using reset password form.'
      )
    );
  }
}

export function isRunningStandalone() {
  return window.matchMedia('(display-mode: standalone)').matches;
}

export const convertMobileNumber = (mobileNumber: string) => {
  if (mobileNumber) {
    if (mobileNumber.length === 10) {
      return `0${mobileNumber}`;
    }
    if (mobileNumber.includes('+98')) {
      return mobileNumber.replace('+98', '0');
    }
  }
  return mobileNumber;
};
