import { createApi } from '@reduxjs/toolkit/dist/query/react';
import * as R from 'ramda';
import baseQuery from '@services/baseQuery';
import {
  TAddBankAccountArgs,
  TAddBankAccountResponse,
  IBankAccount,
  TBankPaymentRequestResponse,
  TCreateBankPaymentRequestArgs,
  TDeleteBankAccountArgs,
  TGetBankAccountsParams,
  TUpdateBankAccountArgs,
  TGetBankAccountResponse,
} from './types';

export const BANK_API_REDUCER_KEY = 'BANK_API_REDUCER_KEY';

const BANK_ACCOUNT_TAG: string = 'bank-account';

function providesList<R extends TGetBankAccountResponse, T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...Object.keys(resultsWithIds).map((id) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}

const bankApi = createApi({
  reducerPath: BANK_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [BANK_ACCOUNT_TAG],
  endpoints: (builder) => ({
    getBankAccounts: builder.query<TGetBankAccountResponse, TGetBankAccountsParams>({
      query: (values) => ({
        path: '/v1/bank-account',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      transformResponse: (response: IBankAccount[]) =>
        R.indexBy<IBankAccount, string>(R.prop('id'), response),
      providesTags: (result) => providesList(result, BANK_ACCOUNT_TAG),
    }),
    addBankAccount: builder.mutation<TAddBankAccountResponse, TAddBankAccountArgs>({
      query: (values) => ({
        path: '/v1/bank-account',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [{ type: BANK_ACCOUNT_TAG, id: 'LIST' }],
    }),
    updateBankAccount: builder.mutation<void, TUpdateBankAccountArgs>({
      query: (values) => ({
        path: '/v1/bank-account',
        method: 'PATCH',
        data: values,
      }),
      invalidatesTags: (result, error, args) => [{ type: BANK_ACCOUNT_TAG, id: args.accountId }],
    }),
    deleteBankAccount: builder.mutation<void, TDeleteBankAccountArgs>({
      query: (values) => ({
        path: '/v1/bank-account',
        method: 'DELETE',
        data: values,
      }),
      invalidatesTags: [{ type: BANK_ACCOUNT_TAG, id: 'LIST' }],
    }),
    createBankPaymentRequest: builder.mutation<
      TBankPaymentRequestResponse,
      TCreateBankPaymentRequestArgs
    >({
      query: (values) => ({
        path: `/v1/bank-payment-request/${values.amount}`,
        method: 'POST',
        data: values,
      }),
    }),
  }),
});

export default bankApi;
