import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TAddTagArgs, TAddTagResponse } from '@services/tracking/types';
import { IBaseQueryError } from '@services/types';
import { domainName, trackingUrl } from '@constants/configs';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const TRACK_API_REDUCER_KEY = 'TRACK_API_REDUCER_KEY';

const trackingApi = createApi({
  reducerPath: TRACK_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({ baseUrl: trackingUrl }),
  endpoints: (builder) => ({
    addTag: builder.mutation<TAddTagResponse, TAddTagArgs>({
      query: (values) => ({
        url: '/sync/api/v1/addTag',
        method: 'POST',
        body: values,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          const { data } = res.data;
          const localeUID = cookies.get('UID');

          if (localeUID && localeUID !== data.id && !data.id.includes('ANONYMOUS')) {
            const d = new Date();
            d.setFullYear(d.getFullYear() + 1);
            cookies.set('UID', data.id, { domain: `.${domainName}`, path: '/', expires: d });
          } else if (!localeUID) {
            const d = new Date();
            d.setFullYear(d.getFullYear() + 1);
            cookies.set('UID', data.id, { domain: `.${domainName}`, path: '/', expires: d });
          }
        } catch (err) {
          throw err as IBaseQueryError;
        }
      },
    }),
  }),
});

export default trackingApi;
