export enum TagTypes {
  SITE_VISIT = 'SITE_VISIT',
  SEND_OTT_CODE_LOGIN = 'SEND_OTT_CODE_LOGIN',
  SEND_OTT_CODE_REGISTER = 'SEND_OTT_CODE_REGISTER',
  SITE_REGISTER = 'SITE_FORM_REGISTER_CLICK',
  SITE_REGISTER_VERIFY = 'SITE_VERIFY_REGISTER_CLICK',
  SITE_ADD_NUMBER = 'SITE_ADD_NUMBER',
  SITE_CLICK = 'SITE_CLICK',
  CONNECTED_ZARINPAL = 'CONNECTED_ZARINPAL',
  TRADE_BUY = 'TRADE_BUY',
  TRADE_SELL = 'TRADE_SELL',
  GOOGLE_2FA = 'GOOGLE_2FA',
  SMS_2FA = 'SMS_2FA',
  KYC_SHAHKAR_CLICK = 'KYC_SHAHKAR_CLICK',
  KYC_LEVEL_2 = 'KYC_LEVEL_2',
}

export type TAddTagArgs = {
  phoneNumber?: string;
  email?: string;
  id?: string;
  tag: TagTypes;
  value?: string;
  firstName?: string;
  lastName?: string;
};

export type TAddTagResponse = {
  data: {
    id: string;
  };
};
