import pathnames from './pathnames';

// Platform
export const platformName = 'Zarindax';
export const defaultMarket = 'BTC/TMN';
export const baseTokenSymbol = 'TMN';
export const defaultWallet = 'BTC';
export const defaultDecimal = 6;

// UI
export const isThemeSelectorActive = true;
export const isLocaleSelectorActive = false;
export const screensWithFooter = [pathnames.MARKETS, pathnames.WALLETS];
export const screenMobileWidth = { query: '(max-width: 768px)' };

// Services
export const requestsTimeout = 60000;
export const fetchIntervalTime = 15000;
export const debounceWaitTime = 500;

// Tables
export const openOrdersTableLimit = 5;
export const ordersHistoryTableLimit = 5;
export const tradesHistoryTableLimit = 5;
export const walletsTableLimit = 5;
export const assetsTableLimit = 5;
export const referralsTableLimit = 5;
export const transactionsHistoryTableLimit = 5;
