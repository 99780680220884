import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from '@services/baseQuery';
import * as R from 'ramda';
import {
  IBankCard,
  TAddIbanArgs,
  TAddIbanResponse,
  TAddBankCardArgs,
  TAddBankCardResponse,
  TDeleteIbanArgs,
  TDeleteBankCardArgs,
  TGetIbansArgs,
  TGetIbansResponse,
  TGetBankCardArgs,
  TGetBankCardResponse,
  TUpdateIbanArgs,
  TUpdateBankCardArgs,
  IIban,
} from './types';

export const BANK_V2_API_REDUCER_KEY = 'BANK_V2_API_REDUCER_KEY';

const BANK_CARD_TAG: string = 'bank-card';
const BANK_ACCOUNT_TAG: string = 'bank-account';

function providesList<R extends TGetBankCardResponse | TGetIbansResponse, T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...Object.keys(resultsWithIds).map((id) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}

export const bankApi = createApi({
  reducerPath: BANK_V2_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [BANK_CARD_TAG, BANK_ACCOUNT_TAG],
  endpoints: (builder) => ({
    getBankCards: builder.query<TGetBankCardResponse, TGetBankCardArgs>({
      query: (values) => ({
        path: '/v2/bank-account/card-number',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      transformResponse: (response: IBankCard[]) =>
        R.indexBy<IBankCard, string>(R.prop('id'), response),
      providesTags: (result) => providesList(result, BANK_CARD_TAG),
    }),
    addBankCard: builder.mutation<TAddBankCardResponse, TAddBankCardArgs>({
      query: (values) => ({
        path: '/v2/bank-account/card-number',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [{ type: BANK_CARD_TAG, id: 'LIST' }],
    }),
    updateBankCard: builder.mutation<void, TUpdateBankCardArgs>({
      query: (values) => ({
        path: '/v2/bank-account/card-number',
        method: 'PATCH',
        data: values,
      }),
      invalidatesTags: (result, error, args) => [{ type: BANK_CARD_TAG, id: args.accountId }],
    }),
    deleteBankCard: builder.mutation<void, TDeleteBankCardArgs>({
      query: (values) => ({
        path: '/v2/bank-account/card-number',
        method: 'DELETE',
        data: values,
      }),
      invalidatesTags: [{ type: BANK_CARD_TAG, id: 'LIST' }],
    }),
    getIbans: builder.query<TGetIbansResponse, TGetIbansArgs>({
      query: (values) => ({
        path: '/v2/bank-account/iban',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      transformResponse: (response: IIban[]) => R.indexBy<IIban, string>(R.prop('id'), response),
      providesTags: (result) => providesList(result, BANK_ACCOUNT_TAG),
    }),
    addIban: builder.mutation<TAddIbanResponse, TAddIbanArgs>({
      query: (values) => ({
        path: '/v2/bank-account/iban',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [{ type: BANK_ACCOUNT_TAG, id: 'LIST' }],
    }),
    updateIban: builder.mutation<void, TUpdateIbanArgs>({
      query: (values) => ({
        path: '/v2/bank-account/iban',
        method: 'PATCH',
        data: values,
      }),
      invalidatesTags: (result, error, args) => [{ type: BANK_ACCOUNT_TAG, id: args.accountId }],
    }),
    deleteIban: builder.mutation<void, TDeleteIbanArgs>({
      query: (values) => ({
        path: '/v2/bank-account/iban',
        method: 'DELETE',
        data: values,
      }),
      invalidatesTags: [{ type: BANK_ACCOUNT_TAG, id: 'LIST' }],
    }),
  }),
});

export default bankApi;
