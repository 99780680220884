import { createApi } from '@reduxjs/toolkit/query/react';
import * as R from 'ramda';
import baseQuery from '@services/baseQuery';
import {
  TGetPairsResponse,
  TGetTokensResponse,
  TGetNotificationsParams,
  TGetNotificationsResponse,
  IPair,
  IToken,
  TGetNetworksResponse,
  INetwork,
  TGetSettingsResponse,
  TGetZonesResponse,
  IZone,
} from './types';

export const GENERAL_API_REDUCER_KEY = 'GENERAL_API_REDUCER_KEY';

const GET_NOTIFICATIONS_TAG: string = 'notifications';
const GET_PAIRS_TAG: string = 'pairs';
const GET_TOKENS_TAG: string = 'tokens';
const GET_NETWORKS_TAG: string = 'networks';
const GET_SETTINGS_TAG: string = 'settings';
const GET_ZONES_TAG: string = 'zones';

const generalApi = createApi({
  reducerPath: GENERAL_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [GET_NOTIFICATIONS_TAG, GET_PAIRS_TAG, GET_TOKENS_TAG, GET_SETTINGS_TAG, GET_ZONES_TAG],
  endpoints: (builder) => ({
    getNotifications: builder.query<
      Omit<TGetNotificationsResponse, 'unseenCount'>,
      TGetNotificationsParams
    >({
      query: (values) => ({
        path: '/v1/notifications',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_NOTIFICATIONS_TAG],
    }),
    getPairs: builder.query<TGetPairsResponse, void>({
      query: () => ({
        path: '/v1/pairs',
        method: 'GET',
      }),
      transformResponse: (response: IPair[]) => R.indexBy<IPair, string>(R.prop('name'), response),
      providesTags: [GET_PAIRS_TAG],
    }),
    getTokens: builder.query<TGetTokensResponse, void>({
      query: () => ({
        path: '/v1/tokens',
        method: 'GET',
      }),
      transformResponse: (response: IToken[]) =>
        R.indexBy<IToken, string>(R.prop('symbol'), response),
      providesTags: [GET_TOKENS_TAG],
    }),
    getNetworks: builder.query<TGetNetworksResponse, void>({
      query: () => ({
        path: '/v1/networks',
        method: 'GET',
      }),
      transformResponse: (response: INetwork[]) =>
        R.indexBy<INetwork, string>(R.prop('name'), response),
      providesTags: [GET_NETWORKS_TAG],
    }),
    getZones: builder.query<TGetZonesResponse, void>({
      query: () => ({
        path: '/v1/zones',
        method: 'GET',
      }),
      transformResponse: (response: IZone[]) => R.indexBy<IZone, string>(R.prop('name'), response),
      providesTags: [GET_ZONES_TAG],
    }),
    getSettings: builder.query<TGetSettingsResponse, void>({
      query: () => ({
        path: '/v1/settings',
        method: 'GET',
      }),
      providesTags: [GET_SETTINGS_TAG],
    }),
  }),
});

export default generalApi;
