import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { omit } from 'ramda';
import tokenProvider from 'axios-token-interceptor';
import moment from 'moment';
import { application } from '../../constants/configs';
import { requestsTimeout } from '../../constants/defaultValues';
import { IAxiosInvokeOpts } from './types';
import { signOut } from '../../utils/app';

const axiosInvoke = async (axiosInvokeOpts: IAxiosInvokeOpts) => {
  const { method, path, data, options, authenticate } = axiosInvokeOpts;
  const REFRESH_JWT_PATH = '/v2/auth/refreshToken';

  const instance = axios.create({
    method,
    url: application.apiUrl + path,
    headers: {
      ...(options && options.headers ? options.headers : null),
      'Accept-Language': 'en',
      'Cache-Control': 'no-cache',
    },
    params: data ? data.params : undefined,
    data,
    ...(data instanceof FormData
      ? {
          cancelToken: undefined,
          timeout: 0,
        }
      : {
          cancelToken: new axios.CancelToken((cancel) => {
            setTimeout(() => {
              cancel();
            }, requestsTimeout);
          }),
        }),
    ...omit(['headers'], options),
  });

  if (authenticate) {
    const now = moment();
    const accessToken = localStorage.getItem('@accessToken');
    const expiresAt = localStorage.getItem('@expiresAt');
    if (!accessToken || !expiresAt || moment(expiresAt).diff(now) < 0) {
      signOut();
    }
    if (path !== REFRESH_JWT_PATH && moment(expiresAt).diff(now, 'hours') < 2) {
      const response = await axiosInvoke({
        method: 'POST',
        path: REFRESH_JWT_PATH,
        authenticate: true,
      });
      const newExpiresAt = now.add(response.data.expiresIn, 'seconds').toISOString();
      localStorage.setItem('@accessToken', response.data.token);
      localStorage.setItem('@expiresAt', newExpiresAt);
    }
    const token = localStorage.getItem('@accessToken');
    if (token) {
      instance.interceptors.request.use(
        tokenProvider({
          token,
        }) as
          | ((value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>)
          | undefined
      );
    }
  }

  try {
    return await instance.request({});
  } catch (e) {
    throw e as AxiosError;
  }
};

export default axiosInvoke;
