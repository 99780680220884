import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from '@services/baseQuery';
import { IBaseQueryError } from '@services/types';
import moment from 'moment';
import {
  TAuthInitArgs,
  TAuthInitResponse,
  TAuthStatusResponse,
  TGenerateOneTimeTokenArgs,
  TGenerateZarinpalConnectLinkArgs,
  TGenerateZarinpalConnectLinkResponse,
  TLoginArgs,
  TLoginResponse,
  TRefreshTokenResponses,
  TRegisterArgs,
  TResetPasswordArgs,
  TSendAuthStatusTokenArgs,
  TVerifyAuthStatusTokenArgs,
  TVerifyOneTimeTokenArgs,
} from './types';

export const AUTH_V2_API_REDUCER_KEY = 'AUTH_V2_API_REDUCER_KEY';

const AUTH_STATUS_TAG: string = 'auth-status';

export const authApi = createApi({
  reducerPath: AUTH_V2_API_REDUCER_KEY,
  baseQuery,
  tagTypes: [AUTH_STATUS_TAG],
  endpoints: (builder) => ({
    register: builder.mutation<void, TRegisterArgs>({
      query: (values) => ({
        path: '/v2/auth/register',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
    }),
    login: builder.mutation<TLoginResponse, Partial<TLoginArgs>>({
      query: (values) => ({
        path: '/v2/auth/login',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data && data.token && data.expiresIn) {
            const expiresAt = moment().add(data.expiresIn, 'seconds').toISOString();
            localStorage.setItem('@accessToken', data.token);
            localStorage.setItem('@expiresAt', expiresAt);
          }
        } catch (err) {
          throw err as IBaseQueryError;
        }
      },
    }),
    authInit: builder.mutation<TAuthInitResponse, Partial<TAuthInitArgs>>({
      query: (values) => ({
        path: '/v2/auth/init',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data && data.token && data.expiresIn) {
            const expiresAt = moment().add(data.expiresIn, 'seconds').toISOString();
            localStorage.setItem('@accessToken', data.token);
            localStorage.setItem('@expiresAt', expiresAt);
          }
        } catch (err) {
          throw err as IBaseQueryError;
        }
      },
    }),
    generateOneTimeToken: builder.mutation<void, TGenerateOneTimeTokenArgs>({
      query: (values) => ({
        path: '/v2/auth/ott',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
    }),
    verifyOneTimeToken: builder.mutation<void, TVerifyOneTimeTokenArgs>({
      query: (values) => ({
        path: '/v2/auth/ott/verify',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
    }),
    generateZarinpalConnectLink: builder.mutation<
      TGenerateZarinpalConnectLinkResponse,
      TGenerateZarinpalConnectLinkArgs
    >({
      query: (values) => ({
        path: '/v2/auth/zarinpalConnect',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const { url } = data;
            window.location.href = url;
          }
        } catch (err) {
          throw err as IBaseQueryError;
        }
      },
    }),
    resetPassword: builder.mutation<void, TResetPasswordArgs>({
      query: (values) => ({
        path: '/v2/auth/resetPassword',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
    }),
    refreshToken: builder.mutation<TRefreshTokenResponses, void>({
      query: () => ({
        path: '/v2/auth/refreshToken',
        method: 'POST',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const expiresAt = moment().add(data.expiresIn, 'seconds').toISOString();
          localStorage.setItem('@accessToken', data.token);
          localStorage.setItem('@expiresAt', expiresAt);
        } catch (err) {
          throw err as IBaseQueryError;
        }
      },
    }),
    getAuthStatus: builder.query<TAuthStatusResponse, void>({
      query: () => ({
        path: '/v2/auth/status',
        method: 'GET',
      }),
      providesTags: [AUTH_STATUS_TAG],
    }),
    sendAuthStatusToken: builder.mutation<void, TSendAuthStatusTokenArgs>({
      query: (values) => ({
        path: '/v2/auth/status',
        method: 'POST',
        data: values,
      }),
    }),
    verifyAuthStatusToken: builder.mutation<void, TVerifyAuthStatusTokenArgs>({
      query: (values) => ({
        path: '/v2/auth/verifyStatus',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [AUTH_STATUS_TAG],
    }),
    syncZarinpal: builder.mutation<void, void>({
      query: () => ({
        path: '/v2/auth/zarinpalSync',
        method: 'POST',
      }),
    }),
  }),
});

export default authApi;
