import React from 'react';
import { ToastContainer } from 'react-toastify';
import { selectTheme } from '@modules/app/selectors/themeSelector';
import useAppSelector from '@hooks/useAppSelector';
import i18n from '@utils/i18n';

const ToastConfig = function () {
  const theme = useAppSelector(selectTheme);
  return (
    <ToastContainer
      rtl={i18n.dir(i18n.language) === 'rtl'}
      position={i18n.dir(i18n.language) === 'rtl' ? 'top-left' : 'top-right'}
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      theme={theme}
      toastClassName="rounded-xl"
      bodyClassName="font-en rtl:font-fa"
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default React.memo(ToastConfig);
