import { createApi } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import baseQuery from '@services/baseQuery';
import { IBaseQueryError } from '../../types';
import {
  TLoginResponses,
  TRefreshJwtResponses,
  TLoginArgs,
  TSendResetPasswordEmailArgs,
  TResetPasswordArgs,
  TValidateResetPasswordTokenArgs,
  TSendSmsTokenArgs,
  TRegisterGoogleAuthResponses,
} from './types';

export const AUTH_API_REDUCER_KEY = 'AUTH_API_REDUCER_KEY';

const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<TLoginResponses, TLoginArgs>({
      query: (values) => ({
        path: '/v1/users/login',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data && data.token && data.expiresIn) {
            const expiresAt = moment().add(data.expiresIn, 'seconds').toISOString();
            localStorage.setItem('@accessToken', data.token);
            localStorage.setItem('@expiresAt', expiresAt);
          }
        } catch (err) {
          throw err as IBaseQueryError;
        }
      },
    }),
    sendResetPasswordEmail: builder.mutation<void, TSendResetPasswordEmailArgs>({
      query: (values) => ({
        path: '/v1/users/send-reset-password-email',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
    }),
    resetPassword: builder.mutation<void, TResetPasswordArgs>({
      query: (values) => ({
        path: '/v1/users/reset-password',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
    }),
    validateResetPasswordToken: builder.mutation<void, TValidateResetPasswordTokenArgs>({
      query: (values) => ({
        path: '/v1/users/validate-reset-password-token',
        method: 'POST',
        data: values,
        options: {
          api: {
            authenticate: false,
          },
        },
      }),
    }),
    sendSmsToken: builder.mutation<void, TSendSmsTokenArgs>({
      query: (values) => ({
        path: '/v1/users/send-sms-token',
        method: 'POST',
        data: values,
      }),
    }),
    registerGoogleAuth: builder.mutation<TRegisterGoogleAuthResponses, void>({
      query: (values) => ({
        path: '/v1/users/register-google-auth',
        method: 'POST',
        data: values,
      }),
    }),
    refreshJwt: builder.mutation<TRefreshJwtResponses, void>({
      query: () => ({
        path: '/v1/users/refresh-jwt',
        method: 'POST',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const expiresAt = moment().add(data.expiresIn, 'seconds').toISOString();
          localStorage.setItem('@accessToken', data.token);
          localStorage.setItem('@expiresAt', expiresAt);
        } catch (err) {
          throw err as IBaseQueryError;
        }
      },
    }),
  }),
});

export default authApi;
