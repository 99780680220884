import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AuthInitError } from '@services/v2/auth/types';

/**
 * Log a warning and show a toast!
 */

const undisplayedErrorCodes = [
  AuthInitError.GOOGLE_AUTH_TOKEN_REQUIRED,
  AuthInitError.SMS_AUTH_TOKEN_REQUIRED,
  AuthInitError.PHONE_NUMBER_NOT_REGISTERED,
  AuthInitError.EMAIL_NOT_REGISTERED,
  'credentials_required',
];

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    const { response } = action.payload;
    const { config, data } = response;
    if (
      !undisplayedErrorCodes.includes(data.code) &&
      config.method.toUpperCase() !== 'GET' &&
      data.message
    ) {
      toast(data.message, { type: 'error', toastId: data.message });
    }
  }

  return next(action);
};

export default rtkQueryErrorLogger;
