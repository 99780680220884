import React, { useMemo } from 'react';
import { BarLoader } from 'react-spinners';
import useAppSelector from '@hooks/useAppSelector';
import { selectLang } from '@modules/app/selectors/langSelector';
import { selectTheme } from '@modules/app/selectors/themeSelector';

interface SuspenseLoadingProps {
  width?: number;
  height?: number;
}

const SuspenseLoading: React.FC<SuspenseLoadingProps> = function ({ width, height }) {
  const theme = useAppSelector(selectTheme);
  const lang = useAppSelector(selectLang);

  const logoPath = useMemo(() => {
    if (lang === 'fa-IR') {
      return `logo-${theme}`;
    }
    if (lang === 'en-US') {
      return `logo-en-${theme}`;
    }
  }, [lang, theme]);

  return (
    <div className="flex flex-col w-screen h-screen items-center justify-center">
      <div className="flex flex-col space-y-4 items-center">
        <img
          className="md:w-32 w-28 h-auto my-auto"
          src={`/images/${logoPath}.png`}
          alt="Memedax"
        />

        <BarLoader width={width} height={height} color="#fed40a" />
      </div>
    </div>
  );
};

SuspenseLoading.defaultProps = { width: 200, height: 5 };

export default React.memo(SuspenseLoading);
