import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer, { AUTH_REDUCER_KEY } from '@modules/auth/authSlice';
import orderbookReducer, { ORDERBOOK_REDUCER_KEY } from '@modules/orderbook/orderbookSlice';
import authApi, { AUTH_API_REDUCER_KEY } from '@services/v1/auth/authApi';
import bankApi, { BANK_API_REDUCER_KEY } from '@services/v1/bank/bankApi';
import generalApi, { GENERAL_API_REDUCER_KEY } from '@services/v1/general/generalApi';
import marketInfoApi, { MARKET_INFO_API_REDUCER_KEY } from '@services/v1/marketInfo/marketInfoApi';
import priceApi, { PRICE_API_REDUCER_KEY } from '@services/v1/price/priceApi';
import userApi, { USER_API_REDUCER_KEY } from '@services/v1/user/userApi';
import transactionApi, {
  TRANSACTIONS_API_REDUCER_KEY,
} from '@services/v1/transaction/transactionApi';
import orderApi, { ORDER_API_REDUCER_KEY } from '@services/v1/order/orderApi';
import tradeApi, { TRADE_API_REDUCER_KEY } from '@services/v1/trade/tradeApi';
import appReducer, { APP_REDUCER_KEY } from '@modules/app/appSlice';
import walletApi, { WALLET_API_REDUCER_KEY } from '@services/v1/wallet/walletApi';
import { AUTH_V2_API_REDUCER_KEY } from '@services/v2/auth/authApi';
import { BANK_V2_API_REDUCER_KEY } from '@services/v2/bank/bankApi';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import incomeStatementApi, {
  INCOME_STATEMENT_API_REDUCER_KEY,
} from '@services/v1/incomeStatement/incomeStatementApi';
import transferApi, { TRANSFER_API_REDUCER_KEY } from '@services/v2/transfer/transferApi';
import trackingApi, { TRACK_API_REDUCER_KEY } from '@services/tracking/trackingApi';
import * as V2 from './services/v2';
import errorLogger from './middlewares/errorLogger';
import tracking from './middlewares/tracking';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, incomeStatementApi.reducer);

export const store = configureStore({
  reducer: combineReducers({
    [AUTH_REDUCER_KEY]: authReducer,
    [APP_REDUCER_KEY]: appReducer,
    [ORDERBOOK_REDUCER_KEY]: orderbookReducer,
    [AUTH_API_REDUCER_KEY]: authApi.reducer,
    [MARKET_INFO_API_REDUCER_KEY]: marketInfoApi.reducer,
    [GENERAL_API_REDUCER_KEY]: generalApi.reducer,
    [PRICE_API_REDUCER_KEY]: priceApi.reducer,
    [USER_API_REDUCER_KEY]: userApi.reducer,
    [BANK_API_REDUCER_KEY]: bankApi.reducer,
    [ORDER_API_REDUCER_KEY]: orderApi.reducer,
    [TRADE_API_REDUCER_KEY]: tradeApi.reducer,
    [WALLET_API_REDUCER_KEY]: walletApi.reducer,
    // V2 APIs
    [AUTH_V2_API_REDUCER_KEY]: V2.authApi.reducer,
    [BANK_V2_API_REDUCER_KEY]: V2.bankApi.reducer,
    [TRANSACTIONS_API_REDUCER_KEY]: transactionApi.reducer,
    [TRANSFER_API_REDUCER_KEY]: transferApi.reducer,
    [INCOME_STATEMENT_API_REDUCER_KEY]: persistedReducer,
    [TRACK_API_REDUCER_KEY]: trackingApi.reducer,
  }),
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      generalApi.middleware,
      marketInfoApi.middleware,
      priceApi.middleware,
      userApi.middleware,
      transactionApi.middleware,
      bankApi.middleware,
      orderApi.middleware,
      tradeApi.middleware,
      walletApi.middleware,
      incomeStatementApi.middleware,
      // V2 API Reducers
      V2.authApi.middleware,
      V2.bankApi.middleware,
      transferApi.middleware,
      // TRACK API Reducers
      trackingApi.middleware,
      // Other Middlewares
      tracking,
      // End of other middlewares
      errorLogger,
    ]),
});

/* remove state after logout */
export const resetRTKQueryStates = () => {
  store.dispatch(userApi.util.resetApiState());
  store.dispatch(transactionApi.util.resetApiState());
  store.dispatch(bankApi.util.resetApiState());
  store.dispatch(orderApi.util.resetApiState());
  store.dispatch(tradeApi.util.resetApiState());
  store.dispatch(walletApi.util.resetApiState());
  store.dispatch(bankApi.util.resetApiState());
  store.dispatch(incomeStatementApi.util.resetApiState());
  store.dispatch(transferApi.util.resetApiState());
};

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
