import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from '@services/baseQuery';
import {
  TAuthenticateTransferServiceArgs,
  TAuthenticateTransferServiceResponse,
  TGetTransferServicesBalancesParams,
  TGetTransferServicesBalancesResponse,
  TGetTransferServicesStatusParams,
  TGetTransferServicesStatusResponse,
} from './types';

export const TRANSFER_API_REDUCER_KEY = 'TRANSFER_API_REDUCER_KEY';

export const transferApi = createApi({
  reducerPath: TRANSFER_API_REDUCER_KEY,
  baseQuery,
  endpoints: (builder) => ({
    getTransferServicesBalances: builder.query<
      TGetTransferServicesBalancesResponse,
      TGetTransferServicesBalancesParams
    >({
      query: (values) => ({
        path: '/v2/transfers/services/balances',
        method: 'GET',
        options: { params: values },
      }),
    }),
    getTransferServicesStatus: builder.query<
      TGetTransferServicesStatusResponse,
      TGetTransferServicesStatusParams
    >({
      query: (values) => ({
        path: '/v2/transfers/services/status',
        method: 'GET',
        options: { params: values },
      }),
    }),
    authenticateTransferService: builder.mutation<
      TAuthenticateTransferServiceResponse,
      TAuthenticateTransferServiceArgs
    >({
      query: (values) => ({
        path: `/v2/transfers/services/${values.transferServiceName}/authentication`,
        method: 'POST',
        data: values,
      }),
    }),
  }),
});

export default transferApi;
