import { createApi } from '@reduxjs/toolkit/dist/query/react';
import * as R from 'ramda';
import baseQuery from '@services/baseQuery';
import { convertMobileNumber } from '@utils/tools';
import { TGetNotificationsResponse } from '../general/types';
import {
  TUpdateFavoriteChartsArgs,
  TUploadProfilePictureResponse,
  TUploadProfilePictureArgs,
  TUpdateKYCArgs,
  TSendPhoneNumberValidationTokenArgs,
  TValidatePhoneNumberValidationTokenArgs,
  TNotificationParams,
  TUpdateNotificationsArgs,
  TAddTicketResponse,
  TAddTicketArgs,
  TLoginHistoryResponse,
  TLoginHistoryParams,
  TUpdateKYCResponse,
  TGetUserProfileResponse,
  TGetAuthStatusResponse,
  TGetTradeFeeResponse,
  TGetFavouriteChartsResponse,
  TGetFavouritePairsResponse,
  TUpdateUserAgreementsArgs,
  TGetTicketsResponse,
  TUpdateFavoritePairsArgs,
  TGetTicketsParams,
  TGetNetworkAddressesResponse,
  TGenerateNetworkAddressArgs,
  TEnableGoogleAuthArgs,
  TEnableSmsAuthArgs,
  TDisableGoogleAuthArgs,
  TDisableSmsAuthArgs,
  TGetReferralResponse,
  TCreateReferralArgs,
  IUserNetwork,
  TSendSMSTokenArgs,
  TAddNationalCodeArgs,
  TAddNationalCodeResponse,
  IUser,
} from './types';

export const USER_API_REDUCER_KEY = 'USER_API_REDUCER_KEY';

const GET_PROFILE_TAG = 'profile';
const GET_AUTH_STATUS_TAG = 'auth-status';
const GET_TRADE_FEE_TAG = 'trade-fee';
const GET_FAVORITE_CHARTS_TAG = 'favorite-charts';
const GET_FAVORITE_PAIRS_TAG = 'favorite-pairs';
const GET_NOTIFICATIONS_TAG = 'notifications';
const GET_TICKETS_TAG = 'tickets';
const GET_LOGIN_HISTORY_TAG = 'login-history';
const GET_NETWORKS_ADDRESSES_TAG = 'networks-addresses';
const GET_REFERRALS_TAG = 'referrals';

const userApi = createApi({
  reducerPath: USER_API_REDUCER_KEY,
  baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [
    GET_PROFILE_TAG,
    GET_AUTH_STATUS_TAG,
    GET_TRADE_FEE_TAG,
    GET_FAVORITE_CHARTS_TAG,
    GET_FAVORITE_PAIRS_TAG,
    GET_NOTIFICATIONS_TAG,
    GET_TICKETS_TAG,
    GET_LOGIN_HISTORY_TAG,
    GET_NETWORKS_ADDRESSES_TAG,
    GET_REFERRALS_TAG,
  ],
  endpoints: (builder) => ({
    getProfile: builder.query<TGetUserProfileResponse, void>({
      query: () => ({
        path: '/v1/users/profile',
        method: 'GET',
      }),
      transformResponse: (response: IUser) => ({
        ...response,
        phoneNumber: convertMobileNumber(response.phoneNumber ?? ''),
      }),
      providesTags: [GET_PROFILE_TAG],
    }),
    getAuthStatus: builder.query<TGetAuthStatusResponse, void>({
      query: () => ({
        path: '/v1/users/auth-status',
        method: 'GET',
      }),
      providesTags: [GET_AUTH_STATUS_TAG],
    }),
    getTradeFee: builder.query<TGetTradeFeeResponse, void>({
      query: () => ({
        path: '/v1/users/trade-fee',
        method: 'GET',
      }),
      providesTags: [GET_TRADE_FEE_TAG],
    }),
    getFavouriteCharts: builder.query<TGetFavouriteChartsResponse, void>({
      query: () => ({
        path: '/v1/users/favourite-charts',
        method: 'GET',
      }),
      providesTags: [GET_FAVORITE_CHARTS_TAG],
    }),
    getFavoritePairs: builder.query<TGetFavouritePairsResponse, void>({
      query: () => ({
        path: '/v1/users/favourite-pairs',
        method: 'GET',
      }),
      providesTags: [GET_FAVORITE_PAIRS_TAG],
    }),
    updateFavoriteCharts: builder.mutation<void, TUpdateFavoriteChartsArgs>({
      query: (values) => ({
        path: '/v1/users',
        method: 'PATCH',
        data: values,
      }),
      invalidatesTags: [GET_FAVORITE_CHARTS_TAG],
    }),
    updateFavoritePairs: builder.mutation<void, TUpdateFavoritePairsArgs>({
      query: (values) => ({
        path: '/v1/users',
        method: 'PATCH',
        data: values,
      }),
      async onQueryStarted({ favouritePairs }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getFavoritePairs', undefined, (draft: string[]) => {
            Object.assign(draft, favouritePairs);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [GET_FAVORITE_PAIRS_TAG],
    }),
    addNationalCode: builder.mutation<TAddNationalCodeResponse, TAddNationalCodeArgs>({
      query: (values) => ({
        path: '/v1/users/nationalCode',
        method: 'POST',
        data: values,
      }),
    }),
    updateUserAgreements: builder.mutation<void, TUpdateUserAgreementsArgs>({
      query: (values) => ({
        path: '/v1/users/agreements',
        method: 'PATCH',
        data: values,
      }),
    }),
    uploadProfilePicture: builder.mutation<
      TUploadProfilePictureResponse,
      TUploadProfilePictureArgs
    >({
      query: (values) => {
        const formData = new FormData();
        formData.append('profilePicture', values.profilePicture, 'profilePicture');

        return {
          path: '/v1/users/upload-profile-pic',
          method: 'POST',
          data: formData,
        };
      },
      invalidatesTags: [GET_PROFILE_TAG],
    }),
    deleteProfilePicture: builder.mutation<void, void>({
      query: (values) => ({
        path: '/v1/users/delete-profile-pic',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_PROFILE_TAG],
    }),
    updateKYC: builder.mutation<TUpdateKYCResponse, Partial<TUpdateKYCArgs>>({
      query: (values) => {
        const formData = new FormData();
        Object.keys(values).forEach((key, index) => {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          formData.append(key, Object.values(values)[index]);
        });
        return {
          path: '/v1/users/update-kyc',
          method: 'PUT',
          data: formData,
        };
      },
      invalidatesTags: [GET_PROFILE_TAG],
    }),
    sendPhoneNumberValidationToken: builder.mutation<void, TSendPhoneNumberValidationTokenArgs>({
      query: (values) => ({
        path: '/v1/users/send-phone-number-validation-token',
        method: 'POST',
        data: values,
      }),
    }),
    validatePhoneNumberValidationToken: builder.mutation<
      void,
      TValidatePhoneNumberValidationTokenArgs
    >({
      query: (values) => ({
        path: '/v1/users/validate-phone-number-validation-token',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_PROFILE_TAG],
    }),
    getUserNotifications: builder.query<TGetNotificationsResponse, TNotificationParams>({
      query: (values) => ({
        path: '/v1/users/notifications',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_NOTIFICATIONS_TAG],
    }),
    updateNotifications: builder.mutation<void, TUpdateNotificationsArgs>({
      query: (values) => ({
        path: '/v1/users/notifications',
        method: 'PATCH',
        data: values,
      }),
      invalidatesTags: [GET_NOTIFICATIONS_TAG],
    }),
    getTickets: builder.query<TGetTicketsResponse, TGetTicketsParams>({
      query: (values) => ({
        path: '/v1/users/tickets',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_TICKETS_TAG],
    }),
    addTicket: builder.mutation<TAddTicketResponse, TAddTicketArgs>({
      query: (values) => {
        const formData = new FormData();
        Object.keys(values).forEach((key, index) => {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          formData.append(key, Object.values(values)[index]);
        });
        return {
          path: '/v1/users/tickets',
          method: 'POST',
          data: formData,
        };
      },
      invalidatesTags: [GET_TICKETS_TAG],
    }),
    getLoginHistory: builder.query<TLoginHistoryResponse, TLoginHistoryParams>({
      query: (values) => ({
        path: '/v1/users/login-history',
        method: 'GET',
        options: {
          params: values,
        },
      }),
      providesTags: [GET_LOGIN_HISTORY_TAG],
    }),
    getNetworkAddresses: builder.query<TGetNetworkAddressesResponse, void>({
      query: () => ({
        path: '/v1/users/networks-addresses',
        method: 'GET',
      }),
      providesTags: [GET_NETWORKS_ADDRESSES_TAG],
      transformResponse: (response: IUserNetwork[]) =>
        R.indexBy<IUserNetwork, string>(R.prop('name'), response),
    }),
    generateNetworkAddress: builder.mutation<void, TGenerateNetworkAddressArgs>({
      query: (values) => ({
        path: '/v1/users/network-address',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_NETWORKS_ADDRESSES_TAG],
    }),
    enableGoogleAuth: builder.mutation<void, TEnableGoogleAuthArgs>({
      query: (values) => ({
        path: '/v1/users/enable-google-auth',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_AUTH_STATUS_TAG],
    }),
    disableGoogleAuth: builder.mutation<void, TDisableGoogleAuthArgs>({
      query: (values) => ({
        path: '/v1/users/disable-google-auth',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_AUTH_STATUS_TAG],
    }),
    enableSmsAuth: builder.mutation<void, TEnableSmsAuthArgs>({
      query: (values) => ({
        path: '/v1/users/enable-sms-auth',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_AUTH_STATUS_TAG],
    }),
    sendSMSToken: builder.mutation<void, TSendSMSTokenArgs>({
      query: (values) => ({
        path: '/v1/users/send-sms-token',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_AUTH_STATUS_TAG],
    }),
    disableSmsAuth: builder.mutation<void, TDisableSmsAuthArgs>({
      query: (values) => ({
        path: '/v1/users/disable-sms-auth',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_AUTH_STATUS_TAG],
    }),
    getReferrals: builder.query<TGetReferralResponse, void>({
      query: () => ({
        path: '/v1/users/referrals',
        method: 'GET',
      }),
      providesTags: [GET_REFERRALS_TAG],
    }),
    createReferral: builder.mutation<void, TCreateReferralArgs>({
      query: (values) => ({
        path: '/v1/users/referrals',
        method: 'POST',
        data: values,
      }),
      invalidatesTags: [GET_REFERRALS_TAG],
    }),
  }),
});

export default userApi;
