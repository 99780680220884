export default {
  APP: 'app',
  USER: 'user',
  ERROR: '/error',
  NOT_FOUND: 'not-found',
  AUTH: 'auth',
  GOOGLE_AUTH: 'google-auth',
  SMS_AUTH: 'sms-auth',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  RULES: 'rules',
  MARKETS: 'markets',
  ALL_CRYPTOS: 'all-cryptos',
  SPOT: 'spot',
  FAVORITES: 'favorites',
  TRADE: 'trade',
  DASHBOARD: 'dashboard',
  ASSETS: 'assets',
  WALLETS: 'wallets',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  OPEN_ORDERS: 'open-orders',
  HISTORY: 'history',
  SETTINGS: 'settings',
  ORDERS: 'orders',
  PROFILE: 'profile',
  Financial_Information: 'financial_information',
  NOTIFICATIONS: 'notifications',
  PRIVATE: 'private-notifications',
  GENERAL: 'general-notifications',
  CONVERT: 'convert',
  SUPPORT: 'support',
  NEW_TICKET: 'new',
  TICKET_THREAD: ':threadId',
  REFERRALS: 'referrals',
  FAST_ORDER: 'fast-order',
  SECURITY: 'security',
  KYC: 'kyc',
  LOGIN: 'login',
  STATIC_PASSWORD: 'static-password',
  OTP_AUTH: 'otp-auth',
  VERIFY_ACCOUNT: 'verify-account',
  TWO_FACTOR_AUTH: '2fa',
  PNL: 'pnl',
  REGISTER: 'register',
  TRANSFER: 'transfer',
};
