/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type OrderbookAdapterState = {
  buy: {
    price: number;
    amount: number;
  };
  sell: {
    price: number;
    amount: number;
  };
};

const initialState: OrderbookAdapterState = {
  buy: {
    price: 0,
    amount: 0,
  },
  sell: {
    price: 0,
    amount: 0,
  },
};

export const ORDERBOOK_REDUCER_KEY = 'ORDERBOOK_REDUCER_KEY';

export const orderbookSlice = createSlice({
  name: ORDERBOOK_REDUCER_KEY,
  initialState,
  reducers: {
    selectBuys: (state, action: { payload: { price: number; amount: number } }) => {
      state.buy = action.payload;
      state.sell = { price: 0, amount: 0 };
    },
    selectSells: (state, action: { payload: { price: number; amount: number } }) => {
      state.sell = action.payload;
      state.buy = { price: 0, amount: 0 };
    },
  },
});

export const { actions: Creators } = orderbookSlice;

export const { selectBuys, selectSells } = Creators;

export default orderbookSlice.reducer;
