import { logout } from '@modules/auth/authSlice';
import { resetRTKQueryStates, store } from '@store';

export const signOut = (): void => {
  store.dispatch(logout());
  localStorage.removeItem('@accessToken');
  localStorage.removeItem('@expiresAt');
  localStorage.removeItem('persist:root');
  resetRTKQueryStates();
};
