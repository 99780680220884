import { ITrade } from '../trade/types';

export enum OrderStatus {
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  FILLED = 'FILLED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  NOT_PENDING = 'NOT_PENDING',
}

export enum OrderConditionStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
}

export enum OrderSide {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum OrderType {
  LIMIT = 'LIMIT',
  MARKET = 'MARKET',
  STOP_LIMIT = 'STOP_LIMIT',
}

export enum FillType {
  NORMAL = 'NORMAL',
}

export enum OrderCondition {
  STOP_LIMIT = 'STOP_LIMIT',
  NONE = 'NONE',
}
export interface IOrder {
  id: number;
  amount: string;
  pair: string;
  price: string;
  side: OrderSide;
  status: OrderStatus;
  trades: Omit<ITrade, 'cost' | 'fee' | 'is_maker'>[];
  type: OrderType;
  userId: string;
  clientOrderId: string;
  createdAt: string;
  fillType: FillType;
  filledAmount: string;
  filledPrice: string;
  conditions: ICondition[];
}

export interface ICondition {
  status: OrderConditionStatus;
  timestamp: string;
  end_time: string | null;
  price: number;
  condition: OrderCondition;
}

export type TGetUserOrdersResponse = IOrder[];

export type TGetUserOrderParams = {
  limit?: number;
  offset?: number;
  id?: string | null;
  pair?: string | null;
  status?: string | null;
  condition?: OrderCondition;
  conditionStatus?: OrderConditionStatus | OrderStatus;
  clientOrderIdIn?: string | null;
};

export type TPlaceOrderArgs = {
  pair: string;
  price?: number;
  amount: number;
  side: OrderSide;
  type: string;
  fillType: string;
  clientOrderId: string | undefined;
  conditions?: { price: number }[] | undefined;
};

export type TPlaceOrderResponse = IOrder;

export type TCancelOrderArgs = {
  orderId?: string;
  pair?: string;
  side?: OrderSide;
  force?: boolean;
  type?: OrderType;
};

export type TChangeLowAssetsArgs = {
  tokens: string[];
};

export type TChangeAssetsArgs = {
  change: string;
  tokens: string[];
};

export interface IChangeAssetsResponse {
  token: string;
  response?: {
    status: OrderStatus;
    filledAmount: string | number;
    filledPrice: string | number;
  };
  error?: {
    httpCode: number;
    message: string;
    code: number;
    isOperational: boolean;
  };
}
