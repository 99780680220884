/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { defaultMarket, defaultWallet } from '@constants/defaultValues';
import defaultLanguage, { localeOptions } from '@constants/locales';
import defaultTheme, { themeOptions } from '@constants/themes';

export type ThemeType = keyof typeof themeOptions;
export type LangType = keyof typeof localeOptions;
export type SelectedMarket = string;
export type SelectedWallet = string;

type AppState = {
  theme: ThemeType;
  lang: LangType;
  selectedMarket: SelectedMarket;
  showAllMarkets: boolean;
  selectedWallet: SelectedWallet;
};

const initialState: AppState = {
  theme: localStorage.getItem('theme')
    ? (localStorage.getItem('theme') as ThemeType)
    : defaultTheme,
  lang: localStorage.getItem('lang') ? (localStorage.getItem('lang') as LangType) : defaultLanguage,
  selectedMarket: localStorage.getItem('selectedMarket')
    ? (localStorage.getItem('selectedMarket') as SelectedMarket)
    : defaultMarket.replace('/', '_'),
  showAllMarkets: localStorage.getItem('showAllMarkets')
    ? Boolean(localStorage.getItem('showAllMarkets'))
    : false,
  selectedWallet: localStorage.getItem('selectedWallet')
    ? (localStorage.getItem('selectedWallet') as SelectedWallet)
    : defaultWallet,
};

export const APP_REDUCER_KEY = 'APP_REDUCER_KEY';

export const appSlice = createSlice({
  name: APP_REDUCER_KEY,
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setSelectedMarket: (state, action) => {
      state.selectedMarket = action.payload;
    },
    setShowAllMarkets: (state, action) => {
      state.showAllMarkets = action.payload;
    },
    setSelectedWallet: (state, action) => {
      state.selectedWallet = action.payload;
    },
  },
});

export const { actions: Creators } = appSlice;

export const { setTheme, setLang, setSelectedMarket, setShowAllMarkets, setSelectedWallet } =
  Creators;

export default appSlice.reducer;
