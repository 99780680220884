export type TRegisterArgs = {
  email?: string;
  phoneNumber?: string;
  password: string;
  firstName?: string;
  lastName?: string;
  captchaValue?: string;
  referralToken?: string;
};

export type TLoginArgs = {
  email: string;
  phoneNumber: string;
  otpToken: string;
  password: string;
  googleAuthToken: string;
  smsAuthToken: string;
  googleIdToken: string;
  zarinpalToken: string;
  referralToken: string;
};

export type TLoginResponse = {
  token: string;
  expiresIn: number;
};

export type TAuthInitArgs = {
  email: string;
  phoneNumber: string;
  otpToken: string;
  password: string;
  googleAuthToken: string;
  smsAuthToken: string;
  googleIdToken: string;
  zarinpalToken: string;
  referralToken: string;
};

export type TAuthInitResponse = {
  token: string;
  expiresIn: number;
  googleAuthEnabled?: boolean;
  smsAuthEnabled?: boolean;
};

export enum TRecipientType {
  PHONE_NUMBER_VERIFICATION = 'PHONE_NUMBER_VERIFICATION',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  SMS_OTP = 'SMS_OTP',
  EMAIL_OTP = 'EMAIL_OTP',
  SMS_2FA = 'SMS_2FA',
  EMAIL_2FA = 'EMAIL_2FA',
  SMS_RESET_PASSWORD = 'SMS_RESET_PASSWORD',
  EMAIL_RESET_PASSWORD = 'EMAIL_RESET_PASSWORD',
}

export type TGenerateOneTimeTokenArgs = {
  type: TRecipientType;
  recipient: string;
};

export type TVerifyOneTimeTokenArgs = {
  type: TRecipientType;
  token: string;
  recipient: string;
};

export type TGenerateZarinpalConnectLinkArgs = {
  callbackPath: string;
};

export type TGenerateZarinpalConnectLinkResponse = {
  token: string;
  url: string;
};

export enum AuthInitError {
  USERNAME_PASSWORD_IS_WRONG = 'USERNAME_PASSWORD_IS_WRONG',
  SMS_AUTH_TOKEN_REQUIRED = 'SMS_AUTH_TOKEN_REQUIRED',
  GOOGLE_AUTH_TOKEN_REQUIRED = 'GOOGLE_AUTH_TOKEN_REQUIRED',
  EMAIL_AUTH_TOKEN_REQUIRED = 'EMAIL_AUTH_TOKEN_REQUIRED',
  NOT_CONFIRMED_EMAIL = 'NOT_CONFIRMED_EMAIL',
  NOT_CONFIRMED_PHONE_NUMBER = 'NOT_CONFIRMED_PHONE_NUMBER',
  INVALID_SMS_AUTH_TOKEN = 'INVALID_SMS_AUTH_TOKEN',
  INVALID_GOOGLE_AUTH_TOKEN = 'INVALID_GOOGLE_AUTH_TOKEN',
  USER_STATIC_PASSWORD_IS_NULL = 'USER_STATIC_PASSWORD_IS_NULL',
  EMAIL_NOT_REGISTERED = 'EMAIL_NOT_REGISTERED',
  PHONE_NUMBER_NOT_REGISTERED = 'PHONE_NUMBER_NOT_REGISTERED',
}

export type TResetPasswordArgs = {
  recipient: string;
  token: string;
  type: TRecipientType;
  password: string;
};

export type TRefreshTokenResponses = {
  token: string;
  expiresIn: number;
};

export type TAuthStatusResponse = {
  smsAuth: boolean;
  googleAuth: boolean;
  otp: boolean;
  emailAuth: boolean;
};

export enum ChangeAuthStatusEnum {
  ENABLE_SMS_2FA = 'ENABLE_SMS_2FA',
  ENABLE_EMAIL_2FA = 'ENABLE_EMAIL_2FA',
  DISABLE_SMS_2FA = 'DISABLE_SMS_2FA',
  DISABLE_EMAIL_2FA = 'DISABLE_EMAIL_2FA',
}

export type TSendAuthStatusTokenArgs = {
  type: ChangeAuthStatusEnum;
};

export type TVerifyAuthStatusTokenArgs = {
  type: ChangeAuthStatusEnum;
  token: string;
};
